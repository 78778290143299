import React from 'react';
import * as styles from '../styles/pages/side-effects.module.css';
import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Hero from '../components/Hero/Hero';
import Footnotes from '../components/Footnotes/Footnotes';
import Title from '../components/Title/Title';
import Tabs from '../components/Tabs/Tabs';
import ColumnsConnections from '../components/ColumnsConnections/ColumnsConnections';

import heroImg from '../images/sideEffects/hero.png';
import clsx from 'clsx';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Common side effects | Loqtorzi™ Patient website </title>
      <meta name='description'content='Understand and learn more about the common side effects of Loqtorzi™ treatment'/>
    </>
  )
}

export default function SideEffects() {
  const tabContent = [
    {
      title: <span className='text-center'>LOQTORZI™ + <br/>chemotherapy*</span>,
      text: <div><section className='box-shadow tablet:pt-[2.5rem] tablet:pb-[3.3rem] tablet:pl-[5.2rem]'>
        <div>The most common side effects of LOQTORZI™ + chemotherapy* are:</div>
        <ul className={ clsx(styles.listColumns, styles.list, 'bullet-list bullet-list--blue') }>
          <li>Nausea</li>
          <li className='!mt-[1.15rem] tablet:!mt-0'>Vomiting</li>
          <li className='!mt-[1.15rem] tablet:!mt-0'>Decreased appetite</li>
          <li className='!mt-[1.15rem] tablet:!mt-0'>Constipation</li>
          <li className='!mt-[1.15rem] tablet:!mt-0'>Low levels of thyroid <br/>hormone</li>
          <li className='!mt-[1.15rem] tablet:!mt-0'>Rash</li>
          <li className='!mt-[1.15rem] tablet:!mt-0'>Fever</li>
          <li className='!mt-[1.15rem] tablet:!mt-0'>Diarrhea</li>
          <li className='!mb-0'>Burning or feeling of pins  <br/>and needles in feet and toes</li>
          <li>Cough</li>
          <li>Muscle and bone pain</li>
          <li>Upper respiratory infection</li>
          <li>Sleep problems</li>
          <li>Dizziness</li>
          <li>Feeling generally unwell</li>
        </ul>

        <p className='mt-[4rem]'>These are not all the possible side effects you may experience while taking LOQTORZI™. Your healthcare provider will help you identify and manage your side effects, so please be sure to inform your healthcare team of any new or worsening symptoms.</p>
      </section>

      <GlobalWrapper>
        <Footnotes className={'mt-[2.9rem] desktop:ml-[4.5rem]'} items={[
          {
            bullet:'*',
            text: <span>Chemotherapy is cisplatin and gemcitabine.</span>
          }
        ]}/>
      </GlobalWrapper>
      </div>
    },
    {
      title: <span className='text-center'>LOQTORZI™ <br/>alone</span>,
      text: <section>
        <div className='box-shadow tablet:py-[4.2rem] tablet:pl-[5.2rem]'>
         <p>The most common side effects are:</p>
          <ul className={ clsx(styles.list, 'mt-[1.3rem] pl-[.6rem] bullet-list bullet-list--blue')}>
            <li>Tiredness</li>
            <li>Low levels of thyroid hormone</li>
            <li>Muscle and bone pain</li>
          </ul>
          <p className='mt-[2rem]'>These are not all the possible side effects of LOQTORZI™. Talk to your healthcare provider for medical advice about side effects.</p>
        </div>
      </section>
    }
  ];
  return (
    <Layout>
      <Hero image={ { src: heroImg, alt:'Hero about NPC' } }>
        <Title>
          <h1>What are the most common side effects?</h1>
        </Title>
      </Hero>

      <GlobalWrapper className={'mobileFull'}>
        <Tabs className={'mt-[1.5rem]'} headerClassName={'mx-[1.5rem]'} content={ tabContent } />
      </GlobalWrapper>

      <GlobalWrapper className={'desktop:pb-[4.5rem]'}>
        <ColumnsConnections className={'mt-[2rem] tablet:mt-[5.6rem] desktop:mt-[7rem]'}
          data={[
            {
              title: 'Stay connected',
              link: '/stay-in-touch/',
              text: 'Get more educational tools and resources to help support you on your treatment journey.',
              className: 'is-active'
            },
            {
              title: 'Explore the results',
              link: '/loqtorzi-clinical-trial-results/',
              text: 'See how LOQTORZI™ may give you the chance for more time without your cancer progressing.'
            },
            {
              title: 'Financial resources',
              link: '/loqtorzi-solutions/',
              text: 'We want to provide you with resources that may help give you access and support for treatment.'
            }
          ]}/>
      </GlobalWrapper>
    </Layout>
  );
}
